import React, { useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import styles from './index.module.less';
import { useWindowSize } from 'react-use';
import { HairstyleMainItemModel, HairstyleMainListModel } from '@/types/home';
import { sortColorData } from '../TopContent';
import Image from 'next/image';
import { imgUrl } from '@/shared/utils';
import { useUserProvider } from '@/context/UserProvider';
import UploadImageContent from '@/components/HairChangeContent/UploadImageContent';
import DownloadAndReportContent from '@/components/Common/DownloadAndReportContent';
import { mainLabelReverseMap } from '@/common/mainLabel';

const StyleContent = ({ data }: { data?: HairstyleMainListModel }) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { width } = useWindowSize();
  const { isLogin, onChangedLoginView, onOpenLoginModal } = useUserProvider();
  const [libId, setLibId] = useState<any>(null);
  const tempColorData = sortColorData()[`${data?.main_label}`];

  const getDataList = useMemo(() => {
    if (width > 900) {
      return {
        first: data?.hairstyle_list?.slice(0, 4),
        second: data?.hairstyle_list?.slice(4, 9),
      };
    } else if (width > 768) {
      return {
        first: data?.hairstyle_list?.slice(0, 3),
        second: data?.hairstyle_list?.slice(4, 8),
      };
    } else if (width > 576) {
      return {
        first: data?.hairstyle_list?.slice(0, 2),
        second: data?.hairstyle_list?.slice(4, 7),
      };
    } else {
      return {
        first: data?.hairstyle_list?.slice(0, 1),
        second: data?.hairstyle_list?.slice(4, 8),
      };
    }
  }, [data?.hairstyle_list, width]);

  function getRandomColor(): string {
    // 生成随机的明亮颜色，避免深色
    const getRandomBrightValue = () => {
      // 限制在128-255之间以确保颜色明亮
      return Math.floor(Math.random() * 128) + 128;
    };

    // 生成RGB三个通道的值，确保都在明亮范围内
    const red = getRandomBrightValue();
    const green = getRandomBrightValue();
    const blue = getRandomBrightValue();

    // 转换为16进制颜色代码
    return `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue
      .toString(16)
      .padStart(2, '0')}`;
  }

  const getItem = (item: HairstyleMainItemModel) => {
    return (
      <div key={item?.lib_id} className={styles.hairstyleCard}>
        <div className={styles.imageContainer}>
          <Image
            src={imgUrl(item?.image_url)}
            alt={item?.image_alt}
            loading="lazy"
            width={100}
            height={100}
            className={styles.img}
          />
          <div className={styles.editBadge}>
            <Image
              src={imgUrl(item?.blogger_avatar)}
              alt={''}
              width={24}
              height={24}
              style={{ width: '24px', height: '24px', borderRadius: '12px' }}
            />
            <span>{item?.blogger_name}</span>
          </div>
          <div className={styles.topRightContainer}>
            <DownloadAndReportContent imgUrlStr={item?.image_url} />
          </div>
          <div className={styles.tryOnContainer}>
            <span
              onClick={() => {
                if (isLogin) {
                  setLibId(item?.lib_id);
                } else {
                  onChangedLoginView(true);
                  onOpenLoginModal(true);
                }
              }}
            >
              {t('Try on')}
            </span>
          </div>
        </div>
        <div className={styles.tags}>
          {item?.labels?.map((tag, tagIndex) => {
            const tempColor = getRandomColor();

            return (
              <span
                key={'tag' + tagIndex}
                className={styles.tag}
                style={{ color: tempColor, border: `1px solid ${tempColor}` }}
                onClick={() => {
                  router.push(`/hairstyle?label=${tag}`);
                }}
              >
                {tag}
              </span>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.content}>
        <div className={styles.header}>
          <span
            className={styles.topStyleItem}
            style={{ color: tempColorData?.color, backgroundColor: tempColorData?.backgroundColor }}
          >
            {data?.main_label}
          </span>
        </div>

        <div className={styles.titleSection}>
          <h2>{tempColorData?.title}</h2>
          <p>{tempColorData?.desc}</p>
          <ul style={{ display: 'none' }}>
            {tempColorData?.h3List?.map((item: string, index: number) => {
              return <h3 key={'tempColorData' + index}>{item}</h3>;
            })}
          </ul>
        </div>

        <div className={styles.hairstyleGrid}>
          {getDataList?.first?.map((item, index) => getItem(item))}

          <div className={styles.hairstyleCard}>
            <div
              className={styles.imageContainer}
              style={{ alignItems: 'center' }}
              onClick={() => {
                router.push(mainLabelReverseMap.get(data?.main_label));
              }}
            >
              <Image
                src={'/images/hairstyle/home_empty_item_bg.webp'}
                alt={`Hairstyle`}
                loading="lazy"
                width={100}
                height={100}
                className={styles.img}
              />
              <div className={styles.innerContent}>
                <span>{tempColorData?.introDesc}</span>
                <Image
                  src={'/images/hairstyle/upgrade_icon.webp'}
                  alt={''}
                  width={40}
                  height={40}
                  style={{ width: '40px', height: '40px' }}
                />
              </div>
            </div>
            <div className={styles.tags}>
              <span>{tempColorData?.introDesc}</span>
            </div>
          </div>

          {getDataList?.second?.map((item, index) => getItem(item))}
        </div>

        <div className={styles.exploreContainer}>
          <div
            className={styles.exploreButton}
            onClick={() => {
              if (mainLabelReverseMap.get(data?.main_label)) {
                router.push(mainLabelReverseMap.get(data?.main_label));
              } else {
                router.push(`/influencers`);
              }
            }}
          >
            <span>{tempColorData?.btnTitle}</span>
            <Image
              src={'/images/hairstyle/arrow_icon_1.png'}
              alt={''}
              width={12}
              height={12}
              style={{ width: '12px', height: '12px' }}
            />
          </div>
        </div>
      </div>
      <UploadImageContent openId={libId} handleClose={() => setLibId(null)} />
    </>
  );
};

export default StyleContent;
